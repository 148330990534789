import { ReplaySubject, interval, from } from "rxjs";
import { switchMap, filter } from "rxjs/operators";

const onlineSubject$ = new ReplaySubject(1);
const online$ = onlineSubject$.asObservable();
const urlToHit = `${window.location.origin}/heartbeat`;

onlineSubject$.next(navigator.onLine);

export function setupOnlineListener() {
  window.addEventListener("offline", onOffline);
  window.addEventListener("online", onOnline);
}
setupOnlineListener();

async function onOffline(evt) {
  const { warningToast } = await SystemJS.import("toast-service!sofe");
  warningToast("Network disconnected!");
  if (navigator && navigator.onLine != undefined) {
    onlineSubject$.next(navigator.onLine);
  }
}

let pollingSubscription;

async function onOnline() {
  const { successToast } = await SystemJS.import("toast-service!sofe");
  clearPoll();
  pollingSubscription = interval(1000)
    .pipe(
      filter((i) => {
        if (i === 0) {
          return true;
        } else if (i <= 15) {
          return i % 5 === 0;
        } else if (i >= 20) {
          return i % 10 === 0;
        }
      }),
      switchMap(() => from(checkIfOnline()))
    )
    .subscribe((results) => {
      if (results.status === 200) {
        onlineSubject$.next(true);
        successToast("Network reconnected!");
        clearPoll();
      }
    });
}

function checkIfOnline() {
  return fetch(urlToHit).catch((e) => {
    return {};
  });
}

function clearPoll() {
  if (pollingSubscription && pollingSubscription.unsubscribe) {
    pollingSubscription.unsubscribe();
  }
}

export const onlineListener = online$;
